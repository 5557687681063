import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { store } from "../../redux/store";
import NavSidebar from "../Navigation/NavSidebar";
import { GiHamburgerMenu } from "react-icons/gi";
import { selectIsEngineer } from "../../redux/reducers/userSlice";
import { useSelector } from "react-redux";

function Sidebar() {
	const userState = store.getState().user.user;

	const [navHeight, setNavHeight] = useState<number | string>(0);

	const isEngineer = useSelector(selectIsEngineer);

	return (
		<div className="shrink-0 w-full shrink-0 bg-primaryDarker flex flex-col">
			<div className="flex-1">
				<div className="flex items-center p-4  font-display text-white border-b-2 border-primary">
					<div className="flex-1">
						<div
							onClick={() => setNavHeight(navHeight === 0 ? "auto" : 0)}
							className=" cursor-pointer pl-4"
						>
							<GiHamburgerMenu size={28} />
						</div>
					</div>
					<div>
						<div className="items-center flex flex-row">
							<div className="text-lg leading-none mr-2">Welcome back</div>
							<div className="flex-1  text-lg font-bold">{userState?.first_name}</div>
						</div>
						{!isEngineer && (
							<Link to="/account/details" className="text-sm hidden ">
								Manage Account
							</Link>
						)}
					</div>
				</div>
				<NavSidebar revealHeight={navHeight} />
			</div>
			<div className="hidden fixed bottom-[20px]  flex-col text-white pb-5 px-6">
				<a href="https://www.processinstrumentsolutions.co.uk/" target="_blank">
					processinstrumentsoultions.co.uk
				</a>
				<a href="tel:+441449674986">+44 (0)1449 674 986</a>
			</div>
		</div>
	);
}

export default Sidebar;
