import { useRef } from "react";
import { useInfiniteQuery } from "../../hooks/useInfiniteQuery";
import { fetchPaginatedSchedule, searchSchedule } from "../../services/api/schedule";
import { Schedule } from "../../types/Schedule";
import NoDataReturned from "../Common/NoDataReturned";
import SkeletonList from "../Common/SkeletonList";
import ScheduleRow from "./ScheduleRow";

const ScheduleList = ({ searchTerm }: { searchTerm?: undefined | string }) => {
	const observerElem = useRef(null);
	const isSearching = searchTerm !== undefined && searchTerm.trim() !== "";

	const allSchedulesQuery = useInfiniteQuery({
		queryKey: ["Schedule"],
		queryFn: fetchPaginatedSchedule,
		loadMoreRef: observerElem,
	});

	const searchQuery = useInfiniteQuery({
		queryKey: ["Schedule", "Search", searchTerm ?? ""],
		queryFn: searchSchedule,
		loadMoreRef: observerElem,
		enabled: isSearching === true,
	});

	const query = isSearching ? searchQuery : allSchedulesQuery;

	const noDataText = isSearching
		? "You have no items in your instrument schedule matching ’" + searchTerm + "’ available"
		: "You have no items in your instrument schedule";

	return query.status === "loading" ? (
		// <Loading />
		<SkeletonList rows={10} />
	) : query.status === "error" ? (
		<p>Error: {query.error.message}</p>
	) : (
		<>
			{query.data?.pages[0].results.length ? (
				<>
					{isSearching ? <div className="mb-4 text-sm">Showing results for: {searchTerm}</div> : null}
					<div className="overflow-x-scroll">
						<div className="flex py-3 px-3  text-xs font-display text-secondary uppercase ">
							<div className="w-[100px] shrink-0">Instrument Location</div>
							<div className="w-[100px] shrink-0">Measurement Type</div>
							<div className="w-[120px] shrink-0">Manufacturer</div>
							<div className="w-[100px] shrink-0">Model</div>
							<div className="w-[150px] shrink-0">P/N</div>
							<div className="w-[120px] shrink-0">S/N</div>
							<div className="w-[100px] shrink-0">Range</div>
							<div className="w-[100px] shrink-0">Unit</div>
							<div className="w-[140px] shrink-0">Calibration done</div>
							<div className="w-[140px] shrink-0">Calibration due</div>
							<div className="w-[50px] shrink-0">Status</div>
							<div className="w-[18px] shrink-0"></div>
						</div>

						{query.data?.pages.map((page, i) =>
							page.results?.map((schedule: Schedule, index: number) => <ScheduleRow data={schedule} />)
						)}

						<div ref={observerElem}>
							{query.isFetchingNextPage ? (
								<SkeletonList rows={3} />
							) : query.hasNextPage ? (
								<SkeletonList rows={3} />
							) : (
								""
							)}
						</div>
					</div>
				</>
			) : (
				<NoDataReturned text={noDataText} />
			)}
		</>
	);
};

export default ScheduleList;
