import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectIsEngineer } from "../../redux/reducers/userSlice";
import Watermark from "../Misc/Watermark";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { clsx } from "clsx";

interface Props {
	children: ReactNode;
	overflow?: boolean;
}

export default function StandardLayout({ children, overflow = false }: Props) {
	const isEngineer = useSelector(selectIsEngineer);

	return (
		<div className="min-h-screen flex flex-col bg-body">
			<Header title={`${isEngineer ? "Engineer" : "Customer"} Portal`} />
			<div className="flex flex-1 z-10 flex-col">
				<Sidebar />
				<div className={clsx("flex-1 p-4 sm:p-6 md:p-8", overflow ? "overflow-hidden" : "")}>{children}</div>
			</div>
			<Watermark />
		</div>
	);
}
