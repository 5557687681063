import classNames from "classnames";
import dayjs from "dayjs";
import { Schedule } from "../../types/Schedule";
import { useState } from "react";

interface Props {
	data: Schedule;
}

const ScheduleRow = ({ data }: any) => {
	const [height, setHeight] = useState<number | string>(0);

	/*Traffic light functionality
	Green if more than 30 days due
	Orange if due within 30 days
	Red if overdue
	*/
	const themeMap = {
		green: "bg-green",
		yellow: "bg-yellow",
		red: "bg-red",
	};

	//Used for turning the plus into a minus
	let plusStyle = {
		transform: height === 0 ? "rotate(0deg)" : "rotate(90deg)",
		transition: "transform 150ms ease",
	};

	return (
		<div className="bg-white rounded-md text-secondary text-[10px] font-medium font-display mb-2">
			<div
				className="flex justify-start  cursor-pointer bg-white"
				onClick={() => setHeight(height === 0 ? "auto" : 0)}
			>
				<div className="w-[100px] shrink-0 bg-white p-3">{data.location ? data.location : "-"}</div>
				<div className="w-[100px] shrink-0 bg-white p-3">
					{data.order_line?.product?.product_group != null && data.order_line?.product != null
						? data.order_line?.product?.product_group
						: "-"}
				</div>
				<div className="w-[120px] shrink-0 bg-white p-3">
					{data.manufacturer != null && data.manufacturer.label ? data.manufacturer.label : "-"}
				</div>
				<div className="w-[100px] shrink-0 bg-white p-3">
					{data.order_line != null ? data.order_line.title : "-"}
				</div>
				<div className="w-[150px] shrink-0 bg-white p-3">
					{data.order_line?.product?.supplier_code != null && data.order_line?.product != null
						? data.order_line?.product?.supplier_code
						: "-"}
				</div>

				<div className="w-[120px] shrink-0 bg-white p-3">{data.serial ? data.serial : "-"}</div>
				<div className="w-[100px] shrink-0 bg-white p-3">{data.range ? data.range : "-"}</div>
				<div className="w-[100px] shrink-0 bg-white p-3">{data.unit ? data.unit : "-"}</div>
				<div className="w-[140px] shrink-0 bg-white p-3">
					{data.interval.last ? dayjs.unix(data.interval.last).format("DD/MM/YYYY") : "N/A"}
				</div>
				<div className="w-[140px] shrink-0 bg-white p-3">
					{dayjs.unix(data.interval.next).format("DD/MM/YYYY")}
				</div>

				<div className="w-[50px] shrink-0 bg-white p-3">
					<div
						className={classNames(
							"ml-2 sm:ml-5 h-[10px] w-[10px] rounded-full shrink-0 bg-red"
							// `${themeMap[data.interval.next_colour]}`
						)}
					></div>
				</div>

				{data.file_1 !== null && data.file_1.url && (
					<a
						href={data.file_1.url}
						target="_blank"
						className="ml-3 w-[18px] cursor-pointer bg-white shrink-0"
					>
						<img
							className="w-full block"
							src={process.env.PUBLIC_URL + "/svgs/download.svg"}
							alt="Process Instruments"
						/>
					</a>
				)}
			</div>
		</div>
	);
};

export default ScheduleRow;
